import React from "react";

interface FuelCommunityLogoProps {
  color: string;
}

const FuelCommunityLogo = ({ color }: FuelCommunityLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="38"
    viewBox="0 0 44 38"
    className="w-[42px] h-[42px] sm:w-[50px] sm:h-[50px]"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28497 37.5324H0.0463943L35.9888 1.59112C36.3468 1.23288 36.7719 0.948681 37.2398 0.754752C37.7078 0.560838 38.2093 0.460995 38.7158 0.460938H43.9531L28.1928 16.2225C27.4691 16.9451 6.30754 37.5319 5.28497 37.5324ZM8.60821 17.3515H3.36964L19.1312 1.59112C19.4892 1.23288 19.9143 0.948681 20.3822 0.754752C20.8502 0.560838 21.3516 0.460995 21.8582 0.460938H27.0955L11.3352 16.2225C10.6115 16.9451 9.63078 17.3511 8.60821 17.3515ZM38.9644 20.6504H33.7258C32.7032 20.6509 31.7225 21.0569 30.9988 21.7794L15.2385 37.541H20.4758C20.9824 37.541 21.4838 37.4411 21.9518 37.2472C22.4197 37.0533 22.8448 36.7691 23.2028 36.4108L38.9644 20.6504Z"
    />
  </svg>
);

export default FuelCommunityLogo;
